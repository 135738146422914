
//
// VARIABLES
//

// 颜色使用opencolor https://yeun.github.io/open-color/
// 主题色可选 red, pink, grape, violet, indigo, blue, cyan, teal, green, lime, yellow
// 默认为cyan，如果需要更换，可以直接将cyan并替换成你想要的颜色，例如，查找cyan，替换indigo，全部替换，保存，push，完成！


// Colors
$blue: $oc-cyan-8;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$lightGray: #eee;
$white: #fff;

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

//SerifFonts
// $base-font: "Noto Serif","PT Serif",source-han-serif-sc, "Source Han Serif SC", "Source Han Serif CN", "Source Han Serif TC", "Source Han Serif TW", "Source Han Serif","Songti SC", SimSon, serif;
// $code-font: 'Menlo', 'Courier New', 'Monaco', 'Spoqa Han Sans', monospace;
//Sans Fonts
$base-font: "Noto Sans","PT Sans",source-han-sans-sc, "Source Han Sans SC", "Source Han Sans CN", "Source Han Sans TC", "Source Han Sans TW", "Source Han Sans","Songti SC", SimSon, sans;
$code-font: 'Consolas', 'Spoqa Han Sans', 'Monaco', 'Courier New', 'Menlo', monospace;

// Font sizes
$base-font-size: 16px;
$small-font-size: 14px;

// Colors
$base-color: $oc-gray-8;
$base-lighten-color: $oc-gray-6;
$base-lightener-color: $oc-gray-4;

$background-color: $oc-gray-0;
$background-lighten-color: hsla(210, 17%, 98%, 0.42);

$text-color: $base-color;
$link-color: $oc-cyan-8;
$link-lighten-color: $oc-cyan-6;
$link-bghv-color: $oc-cyan-0;
$divider-color: $oc-gray-1;

$error-color:$oc-red-8;

$table-border-color: $oc-gray-2;
$table-background-color: $oc-gray-1;

$blockquote-color: $base-lighten-color;
$blockquote-border-color: $oc-gray-3;

$footnote-link-border-color: $oc-gray-1;
$footnote-link-background-over-color: $oc-gray-1;

// $selection-color: $oc-black;
$selection-background-color: $oc-gray-3;

$tag-index-label-background-color: $oc-gray-2;
$tag-index-count-background-color: hsla(210, 7%, 56%, 0.102);

$footer-border-color: $oc-gray-2;
$footer-background-color: $oc-gray-0;

// List
$li-bottom-space: 0.05em;
$li-bullets-width: 1em;
$li-line-height: 1.55;

$ul-bullets-font: inherit;
$ul-bullets-font-size: 1.4em;
$ul-bullets-font-line-height: 1.2;
$ul-bullets-right-space: .3em;

$ol-bullets-font: inherit;
$ol-bullets-font-size: 1em;
$ol-bullets-font-line-height: inherit;
$ol-bullets-right-space: .3em;

$li-child-size-ratio: 0.95;

$dt-width: 180px;
$dt-dd-space: 20px;
$dd-position: $dt-width+$dt-dd-space;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

// Dark mode

// Colors
$base-color-dark: $oc-gray-1;
$base-lighten-color-dark: $oc-gray-3;
$base-lightener-color-dark: $oc-gray-5;

$background-color-dark: $oc-gray-9;
$background-lighten-color-dark: hsla(210, 7%, 56%, 0.102);

$text-color-dark: $base-color-dark;
$link-color-dark: $oc-cyan-1;
$link-lighten-color-dark: $oc-cyan-3;
$link-bghv-color-dark: $oc-cyan-9;
$divider-color-dark: $oc-gray-8;

$error-color-dark:$oc-red-1;

$table-border-color-dark: $oc-gray-7;
$table-background-color-dark: $oc-gray-8;

$blockquote-color-dark: $base-lighten-color-dark;
$blockquote-border-color-dark: $oc-gray-6;

$footnote-link-border-color-dark: $oc-gray-8;
$footnote-link-background-over-color-dark: $oc-gray-8;

// $selection-color-dark: $oc-black;
$selection-background-color-dark: $oc-gray-6;

$tag-index-label-background-color-dark: $oc-gray-7;
$tag-index-count-background-color-dark: hsla(210, 7%, 56%, 0.102);

$footer-border-color-dark: $oc-gray-7;
$footer-background-color-dark: $oc-gray-9;
